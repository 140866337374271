import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  TextField,
  Typography,
  Stack,
  Drawer,
  ListItemIcon,
  Avatar,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import zoomOut from "./images/zoom-out.png";
import "./ReportCard.css";
import "./Meeting.css";
import locationImage from "./images/location.png";
import rectangleRed from "./images/RectangleRed.png";
import rectangleViolate from "./images/RectangleViolate.png";
import rectangleOrange from "./images/RectangleOrange.png";
import "./tags.css";
import resolved from "./images/TagResolved.jpg";
import openIcon from "./images/TagOpen.png";
import inprogress from "./images/TagsInProgress.jpg";
import editIcon from "./images/edit.png";
import frame from "./images/frame.jpg";
import messageIcon from "./images/messagesIcon.jpg";
import rightIcon from "./images/rightIcon.jpg";
import emailIcon from "./images/sms.png";
import mettingIcon from "./images/people.png";
import noMessageIcon from "./images/messages2.jpg";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import maximizeIcon from "./images/maximize.jpg";
import {
  updateExpandImageUrl,
  updateCommentReport,
} from "../../../redux/ChildSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { fetchAllUsers } from "../../../redux/action/user";
import ReportCardImageExpand from "./ReportCardImageExpand";
import { getClientDetailsFromLocalStorage } from "../../../components/LocalStorage";
import { useDispatch } from "react-redux";
import {
  addNewComment,
  editReport,
  fetchfilteredReports,
  deleteReport,
} from "../../../redux/action/report";
import MeetingPopup from "./MeetingPopup";
import EmailPopup from "./EmailPopup";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const ReportCard = ({ dummyReport, selectedDate,userAction }) => {
  const { message, error, allTags, allStatus } = useSelector(
    (state) => state.report
  );
  console.log("data", message, error, allTags, allStatus);
  console.log(dummyReport);
  const allUsers = useSelector((state) => state.user.allUsers);

  const [currentReportStatus, setCurrentReportStatus] = useState(null);
  // const selectedDate = useSelector((state) => state.child.selectedDate);
  const [open, setOpen] = useState(false);
  const [imgOpen, setImgOpen] = useState(false);
  const [dummyReportId, setDummyReportId] = useState(null);
  const [dummyReportCommentId, setDummyReportCommentId] = useState(null);
  const [openComment, setOpenComment] = useState(false);
  const [tagCurrent, setTagCurrent] = useState({
    label: dummyReport.tags,
    id: 0,
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [openMeeting, setOpenMeeting] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deletePopup, setDeletePopup] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleClose = () => {
    setDeleteOpen(false);
  };
  const handleDelete = async () => {
    dispatch(deleteReport(dummyReport._id));
    const storedClientDetails = getClientDetailsFromLocalStorage();

    await new Promise((resolve) => setTimeout(resolve, 500));
    dispatch(
      fetchfilteredReports(
        storedClientDetails.client,
        selectedDate,
        selectedDate,
        storedClientDetails._id
      )
    );

    setDeleteOpen(false);
  };
  const [statusCurrent, setStatusCurrent] = useState([]);
  const theme = useTheme();
  const [assignCurrent, setAssignCurrent] = useState({
    label: dummyReport.assigned,
    id: 0,
  });
  const dispatch = useDispatch();

  console.log("dummyReport", dummyReport);

  const tags = allTags?.map((obj) => ({
    label: `${obj.name}`,
    id: `${obj._id}`,
  }));
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
  }, [dispatch, error, message]); // Add 'error' and 'message' to the dependencies array

  // Second useEffect

  const assign =
    (allUsers || []).map((user) => ({
      label: user.name,
      id: user._id,
      mail: user.email,
    })) || [];

  const status = allStatus?.map((obj) => obj.name);

  const handleImageExpand = (url, id) => {
    setImgOpen(true);
    dispatch(updateExpandImageUrl({ url, id }));
  };
  const handleCloseImg = () => {
    setImgOpen(false);
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name.includes(" ")
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${name[0]}`,
    };
  }
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  // const handleOpenMeeting = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   setOpenMenu(true);
  // };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
    setState({ ...state, [anchor]: open });
    setAssignCurrent(null);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMediumScreen = useMediaQuery("(min-width:768px)");
  const isLargeScreen = useMediaQuery("(min-width:1650px)");

  const toggleCommentDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenComment(open);
    setState({ ...state, [anchor]: open });
  };

  const cardSx = {
    display: "flex",
    flexDirection: isMediumScreen ? "row" : "column",
    width: isMediumScreen ? "100%" : 320,
    height: isMediumScreen ? "200px" : "380px",
    boxShadow: "0 0 2px rgba(0, 0, 0, 0.4)",

    background: "var(--base-background-white, #FFF)",
    borderRadius: "8px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "5px",
  };
  const mediaWidth = isMediumScreen ? "40%" : 320;
  const saveHandler = async () => {
    try {
      const storedClientDetails = getClientDetailsFromLocalStorage();
      console.log("gg", selectedDate);
      dispatch(
        editReport(dummyReportId, statusCurrent, tagCurrent, assignCurrent)
      );
      setOpen(false);
      await new Promise((resolve) => setTimeout(resolve, 500));
      dispatch(
        fetchfilteredReports(
          storedClientDetails.client,
          selectedDate,
          selectedDate,
          storedClientDetails._id
        )
      );
    } catch (error) {
      // Handle errors
      console.error("Error saving report:", error);
    }
  };

  const handleAddComment = async () => {
    if (newComment !== "") {
      const storedClientDetails = getClientDetailsFromLocalStorage();

      dispatch(updateCommentReport({ dummyReportCommentId, newComment }));

      dispatch(
        addNewComment(
          storedClientDetails.name,
          storedClientDetails._id,
          dummyReportCommentId,
          newComment
        )
      );
      await new Promise((resolve) => setTimeout(resolve, 500));

      dispatch(
        fetchfilteredReports(
          storedClientDetails.client,
          selectedDate,
          selectedDate,
          storedClientDetails._id
        )
      );
    }

    setNewComment("");
    // setOpenComment(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(status, statusCurrent, theme) {
    return {
      fontWeight:
        statusCurrent.indexOf(status) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatusCurrent(value);
  };

  const list = (anchor) => (
    <Box
      className="report-edit-popup-container"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 340,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="report-edit-popup-title-container">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={{ width: "100%" }}
        >
          <Typography
            style={{
              marginLeft: "11px",
              fontFamily: "PlusJakartaSans",
              fontSize: "19px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              margin: "16px",
            }}
          >
            Edit
          </Typography>
          <IconButton onClick={toggleDrawer(anchor, false)}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </Stack>
      </div>
      <Divider />
      <div
        style={{
          margin: "14px",
          backgroundColor: "#F9FAFB",
          height: "140px",
          borderRadius: "0.5rem",
        }}
      >
        <Stack
          style={{ marginLeft: "10px", marginTop: "16px" }}
          flexDirection={"row"}
        >
          <img
            src={dummyReport.imagepath}
            alt="Report"
            width="100"
            height="55"
            style={{ marginTop: "4px" }}
          />
          <div>
            <div style={{ marginLeft: "12px", marginTop: "4px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "24px",
                }}
              >
                #{dummyReport._id.slice(-4)}
              </Typography>
            </div>
            <div style={{ marginLeft: "12px", marginTop: "6px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "16px",
                }}
              >
                {moment.utc(dummyReport.createdAt).format("DD MMM, HH:mm:ss")}
              </Typography>
            </div>
          </div>
        </Stack>
        <Divider style={{ marginTop: "16px" }} />
        <Stack
          direction={"row"}
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"column"}>
            <Typography
              style={{
                fontSize: "12px",
                color: "#919EAB",
                fontFamily: "PlusJakartaSans",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Location
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "black",
                fontFamily: "PlusJakartaSans",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {dummyReport.location}
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              style={{
                fontSize: "12px",
                color: "#919EAB",
                fontFamily: "PlusJakartaSans",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Incident
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "black",
                fontFamily: "PlusJakartaSans",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {dummyReport.violationType}
            </Typography>
          </Stack>
        </Stack>

        <div className="report-edit-popup-status-container-status">
          <Typography
            style={{
              margin: "10px",
              marginTop: "12px",
              fontFamily: "PlusJakartaSans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Status
          </Typography>

          <FormControl sx={{ width: 300, padding: 0, marginLeft: "7px" }}>
            <Select
              value={statusCurrent}
              defaultValue={currentReportStatus}
              onChange={handleChange}
              style={{ padding: "0px" }}
              renderValue={(selected) => {
                if (!selected || selected === "" || selected.length === 0) {
                  return <em>Status</em>;
                }
                return (
                  <div style={{ display: "inline-block" }}>
                    <Box
                      height={30}
                      padding="10px"
                      margin="9px 9px"
                      display="flex"
                      alignItems="center"
                      borderRadius={1}
                      bgcolor={
                        selected === "Open"
                          ? "#FFF3D6"
                          : selected === "Resolved"
                          ? "#F3FFF6"
                          : "#E0F1F5"
                      }
                      width="auto"
                    >
                      <Typography
                        style={{
                          fontFamily: "PlusJakartaSans",
                          fontSize: 14,
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "20px",
                          color:
                            selected === "Open"
                              ? "#694100"
                              : selected === "Resolved"
                              ? "#006100"
                              : "#005278",
                        }}
                      >
                        {selected}
                      </Typography>
                    </Box>
                  </div>
                );
              }}
              MenuProps={MenuProps}
            >
              {status?.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, statusCurrent, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <Typography
            style={{
              margin: "10px",
              marginTop: "13px",
              fontFamily: "PlusJakartaSans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Assign
          </Typography>

          <Autocomplete
            style={{
              margin: "10px",
              marginTop: "10px",
              fontFamily: "PlusJakartaSans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
              width: 300,
            }}
            disablePortal
            id="assign"
            options={assign}
            value={assignCurrent}
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => {
              setAssignCurrent(value);
            }}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Avatar
                  {...stringAvatar(`${option.label} ${option.label}`)}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "10px",
                    fontSize: "15px",
                  }}
                />
                {option.label}
              </Box>
            )}
          />
        </div>
        <div>
          <Typography
            style={{
              margin: "10px",
              marginTop: "10px",
              fontFamily: "PlusJakartaSans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Tags
          </Typography>
          <Autocomplete
            style={{ margin: "10px" }}
            disablePortal
            id="tags"
            options={tags}
            defaultValue={tagCurrent}
            sx={{ width: 300 }}
            onChange={(event, value) => {
              setTagCurrent(value);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>

        <div className="report-edit-popup-footer">
          <Button
            variant="contained"
            onClick={saveHandler}
            style={{
              marginLeft: "230px",
              marginTop: "16px",
              backgroundColor: "#4040F2",
              fontFamily: "PlusJakartaSans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Box>
  );

  const comments = (anchor) => (
    <Box
      className="report-comments-popup-container"
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 340,
        position: "relative",
        height: "100%",
      }}
      role="presentation"
    >
      <div
        style={{
          display: "flex",
          backgroundColor: "#0D0D30",
          color: "white",
          justifyContent: "center",
          alignContent: "center",
          height: "42px",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          style={{ width: "100%" }}
        >
          <Typography
            className="report-comments-popup-title-typography"
            style={{
              fontFamily: "PlusJakartaSans",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "1rem",
            }}
          >
            Comments
          </Typography>
          <IconButton onClick={toggleCommentDrawer(anchor, false)}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </Stack>
      </div>
      {/* <Divider /> */}
      <div
        style={{ margin: "14px", backgroundColor: "#F9FAFB", height: "140px" }}
      >
        <Stack
          style={{ marginLeft: "10px", marginTop: "16px" }}
          flexDirection={"row"}
        >
          <img
            src={dummyReport.imagepath}
            alt="Report"
            width="100"
            height="55"
            style={{ marginTop: "4px" }}
          />
          <div>
            <div style={{ marginLeft: "12px", marginTop: "4px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "24px",
                }}
              >
                #{dummyReport._id.slice(-4)}
              </Typography>
            </div>
            <div style={{ marginLeft: "12px", marginTop: "6px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "16px",
                }}
              >
                {moment.utc(dummyReport.createdAt).format("DD MMM, hh:mm:ss")}
              </Typography>
            </div>
          </div>
        </Stack>
        <Divider style={{ marginTop: "16px" }} />
        <Stack
          direction={"row"}
          style={{
            marginLeft: "30px",
            marginRight: "30px",
            marginTop: "10px",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"column"}>
            <Typography
              style={{
                fontSize: "13px",
                color: "#919EAB",
                fontFamily: "PlusJakartaSans",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Location
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "black",
                fontFamily: "PlusJakartaSans",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {dummyReport.location}
            </Typography>
          </Stack>
          <Stack direction={"column"}>
            <Typography
              style={{
                fontSize: "13px",
                color: "#919EAB",
                fontFamily: "PlusJakartaSans",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Incident
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                color: "black",
                fontFamily: "PlusJakartaSans",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {dummyReport.violationType}
            </Typography>
          </Stack>
        </Stack>
      </div>
      <Stack
        className="comments-body-container"
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {!dummyReport.comments || dummyReport.comments.length === 0 ? (
          <div>
            <div className="report-comments-popup-in-center">
              <img src={noMessageIcon} alt="message.png" />
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                No comment added yet!
              </Typography>
            </div>
          </div>
        ) : (
          <div
            className="report-comments-popup-in-center"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
              height: "100%",
              overflow: "scroll",
            }}
          >
            {dummyReport.comments &&
              dummyReport.comments.map((comment, index) => (
                <div key={index} style={{ width: "100%" }}>
                  <div
                    key={index}
                    style={{
                      marginTop: "20px",
                      margin: "12px",
                    }}
                  >
                    <Stack flexDirection={"row"}>
                      <Avatar {...stringAvatar(String(comment.user))} />
                      <div style={{ marginLeft: 16 }}>
                        <div
                          style={{
                            fontFamily: "PlusJakartaSans",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px",
                            color: "#212B36",
                          }}
                        >
                          {comment.user}
                        </div>
                        <div
                          style={{
                            fontFamily: "PlusJakartaSans",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "16px",
                            color: "#919EAB",
                          }}
                        >
                          {moment(comment?.time).format(
                            "MMM DD, YYYY | hh:mm a"
                          )}
                        </div>
                      </div>
                    </Stack>
                    <div
                      style={{
                        marginLeft: 56,
                        marginTop: 10,
                        fontFamily: "PlusJakartaSans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                        color: "#454F5B",
                      }}
                    >
                      {comment?.message}
                    </div>
                  </div>
                  <Divider style={{ width: "100%" }} />
                </div>
              ))}
          </div>
        )}

        <div className="report-comment-popup-footer">
          <Stack
            className="report-comment-popup-footer-in"
            direction="row"
            alignItems="flex-start"
            justifyItems="center"
          >
            <Avatar
              {...stringAvatar(String("User"))}
              style={{ marginRight: "3px", margin: "10px" }}
            />
            <input
              type="text"
              style={{
                height: "60px",
                border: "none",
                borderBottom: "0px solid #000",
                fontSize: "16px",
                outline: "none",
                marginLeft: "16px",
              }}
              placeholder="Enter text here"
              onChange={(e) => setNewComment(e.target.value)}
            />
            <Button
              variant="text"
              style={{
                fontFamily: "PlusJakartaSans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                color: "#4040F2",
                marginTop: "15px",
                marginRight: "10px",
              }}
              onClick={handleAddComment}
            >
              Post
            </Button>
          </Stack>
        </div>
      </Stack>
    </Box>
  );

  return (
    <Card sx={cardSx}>
      <CardMedia
        sx={{
          width: mediaWidth,
          height: 160,
          flex: "none",
          position: "relative",
          margin: "12px",
          borderRadius: "0.25rem",
        }}
        image={dummyReport.imagepath}
      >
        <div className="each-report-container">
          <ReportCardImageExpand
            imgOpen={imgOpen}
            handleCloseImg={handleCloseImg}
          />
          <img
            className="rectangle"
            alt="Rectangle"
            src={
              dummyReport.tags === "False alert"
                ? rectangleRed
                : dummyReport.tags === "Near miss"
                ? rectangleViolate
                : rectangleOrange
            }
          />
          <Typography
            className="tag-typography"
            style={{
              fontFamily: "PlusJakartaSans",
              fontSize: isLargeScreen ? "16px" : "12px",
            }}
          >
            {dummyReport.tags}
          </Typography>
        </div>

        <div
          className="frame"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            marginBottom: "10px",
            // marginLeft: "2px",
            marginRight: "5px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            borderRadius: "4px",
            margin: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              direction: "column",
              backgroundColor: "rgba(28, 28, 28, 0.6)",
              borderRadius: "0.25rem",
            }}
          >
            <img
              src={locationImage}
              style={{
                marginTop: "4px",
                marginBottom: "4px",
                marginLeft: "4px",
                height: "17px",
              }}
              alt=""
            />
            <Typography
              style={{
                color: "white",
                margin: "4px 5px",
                fontFamily: "PlusJakartaSans",
                fontSize: isLargeScreen ? "14px" : "12px",
              }}
            >
              {dummyReport?.location}
            </Typography>
          </div>
        </div>
        <Chip
          color="primary"
          disabled={false}
          size="small"
          variant="outlined"
          // icon={
          //   <ZoomOutMapIcon style={{ color: "white", marginLeft: "7px" }} />
          // }
          image={
            <img
              src={zoomOut}
              alt=""
              style={{ color: "white", marginLeft: "7px" }}
            />
          }
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            marginTop: "7px",
            marginRight: "7px",
            backgroundColor: "rgba(28, 28, 28, 0.7)",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "27px",
            height: "27px",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            marginTop: "7px",
            marginRight: "7px",
            backgroundColor: "rgba(28, 28, 28, 0.7)",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "27px",
            height: "27px",
          }}
          onClick={() =>
            handleImageExpand(dummyReport.imagepath, dummyReport._id.slice(-7))
          }
        >
          <ReportCardImageExpand
            imgOpen={imgOpen}
            handleCloseImg={handleCloseImg}
          />

          <img
            src={zoomOut}
            alt=""
            style={{ width: "1.25rem", color: "white" }}
          />
        </div>
      </CardMedia>
      <CardContent className="report-card-edit-right-container">
        <Box>
          <Stack className="report-card-right-header" direction="row">
            <Typography
              className="id-typography"
              style={{
                fontFamily: "PlusJakartaSans",
                fontWeight: "bold",
                fontSize: isLargeScreen ? "16px" : "14px",
              }}
            >
              #{dummyReport._id.slice(-7)}
            </Typography>

            <div>
              <img
                src={
                  dummyReport.status === "Resolved"
                    ? resolved
                    : dummyReport.status === "Open"
                    ? openIcon
                    : inprogress
                }
                style={{ marginLeft: "12px" }}
                alt=""
              />
            </div>
            <Stack
              className="report-card-edit-button-container"
              width="100%"
              direction="row"
              justifyContent="flex-end"
              marginTop="-2px"
            >
              {dummyReport.deleted ? (
                // Display the "deleted" image
                // <img
                //   src={deletedIcon} // Replace with the path to your "deleted" image/icon
                //   alt="Deleted"
                //   style={{
                //     width: "24px",
                //     height: "24px",
                //     color: "#BA1A1A",
                //     marginRight: "8px",
                //   }}
                // />
                <span style={{color:"#BA1A1A", fontWeight:"bold",fontFamily: "PlusJakartaSans",}}>Deleted</span>
              ) : (
                // Display edit and delete buttons only if userAction is true
                userAction && (
                  <>
                    {/* Edit Button */}
                    <IconButton
                      style={{ marginLeft: "0px" }}
                      onClick={() => {
                        setOpen(true);
                        setTagCurrent({
                          label: dummyReport.tags,
                          id: dummyReport._id,
                        });
                        setAssignCurrent({
                          label: dummyReport.assigned,
                          id: dummyReport._id,
                        });
                        setDummyReportId(dummyReport._id);
                        setCurrentReportStatus(dummyReport.status);
                        setStatusCurrent(dummyReport.status);
                        const storedClientDetails =
                          getClientDetailsFromLocalStorage();
                        if (storedClientDetails) {
                          dispatch(fetchAllUsers(storedClientDetails.client));
                        }
                      }}
                    >
                      <img src={editIcon} alt="Edit" />
                    </IconButton>

                    {/* Delete Button */}
                    <IconButton aria-label="delete">
                      <DeleteIcon
                        style={{ color: "#919EAB" }}
                        onClick={() => setDeleteOpen(true)}
                      />
                    </IconButton>

                    {/* Delete Confirmation Dialog */}
                    <Dialog
                      open={deleteOpen}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        style: {
                          width: "400px",
                          height: "200px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "32px 20px",
                        },
                      }}
                      sx={{
                        "& .MuiDialogContent-root": { overflow: "hidden" },
                      }}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Are You Sure You Want to Delete ?"}
                      </DialogTitle>

                      <DialogActions>
                        <Button
                          onClick={handleClose}
                          sx={{
                            position: "relative",
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#26408A",
                            padding: "0.5rem 1rem",
                            cursor: "pointer",
                            width: "100px",
                            height: "35px",
                            borderRadius: "20px",
                            border: "2px solid transparent",
                            backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
                            backgroundOrigin: "padding-box, border-box",
                            backgroundClip: "padding-box, border-box",
                            marginRight: "24px",
                            textTransform: "capitalize",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleDelete}
                          autoFocus
                          sx={{
                            position: "relative",
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "16px",
                            fontWeight: 600,
                            padding: "0.5rem 1rem",
                            cursor: "pointer",
                            width: "100px",
                            height: "35px",
                            borderRadius: "20px",
                            border: "2px solid transparent",
                            backgroundColor: "#BA1A1A",
                            color: "#fff",
                            textTransform: "capitalize",
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#BA1A1A",
                              border: "2px solid #BA1A1A",
                              textTransform: "capitalize",
                            },
                          }}
                        >
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )
              )}
            </Stack>
          </Stack>
          <Stack className="report-card-right-lower-container">
            <div style={{ display: "flex", marginLeft: "6px" }}>
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans",
                  fontSize: isLargeScreen ? "14px" : "12px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#4F5B67",
                  fontStyle: "normal",
                }}
              >
                {moment.utc(dummyReport.createdAt).format("DD MMM, HH:mm:ss")}
              </Typography>
            </div>
            <div className="report-card-right-center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={maximizeIcon} alt="" />
                <Typography
                  style={{
                    marginLeft: "8px",
                    fontFamily: "PlusJakartaSans",
                    fontSize: isLargeScreen ? "16px" : "12px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontStyle: "normal",
                    color: "#454F5B",
                  }}
                >
                  {dummyReport.violationType}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <img src={frame} alt="" />
                <Typography
                  style={{
                    fontFamily: "PlusJakartaSans",
                    fontSize: isLargeScreen ? "16px" : "12px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontStyle: "normal",
                    color: "#454F5B",
                    marginLeft: "8px",
                  }}
                >
                  {dummyReport.assigned}
                </Typography>
              </div>
            </div>

            <div
              className="report-card-left-bottom"
              style={{ display: "flex", margin: "5px", marginTop: "15px" }}
            >
              <Button
                variant="text"
                disabled={dummyReport.deleted}
                // color="primary"
                style={{
                  backgroundColor: "white",
                  color: dummyReport.comments.length === 0 ? "blue" : "black",
                  marginLeft: "-4px",
                }}
                startIcon={
                  // <ForumIcon fontSize="medium" style={{ marginTop: "2px" }} />
                  <img src={messageIcon} alt="" />
                }
                onClick={() => {
                  // addComment();
                  setOpenComment(true);
                  setDummyReportCommentId(dummyReport._id);
                }}
                endIcon={
                  // <ForumIcon fontSize="medium" style={{ marginTop: "2px" }} />
                  dummyReport.comments && dummyReport.comments.length > 0 ? (
                    <img src={rightIcon} alt="" />
                  ) : null
                }
              >
                <Typography
                  style={{
                    marginLeft: "6px",
                    marginTop: "2px",
                    fontFamily: "PlusJakartaSans",
                    fontSize: isLargeScreen ? "16px" : "12px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    color:
                      dummyReport && dummyReport.comments.length > 0
                        ? "#454F5B"
                        : "#4040F2",
                    textTransform: "none",
                  }}
                >
                  {dummyReport.comments && dummyReport.comments.length > 0
                    ? dummyReport.comments.length + " Comments"
                    : "Add Comments"}
                </Typography>
              </Button>
            </div>
          </Stack>
        </Box>
      </CardContent>

      {open === true ? (
        <Drawer
          anchor={"right"}
          open={open}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      ) : null}

      {openComment === true ? (
        <Drawer
          anchor={"right"}
          open={openComment}
          onClose={toggleCommentDrawer("right", false)}
        >
          {comments("right")}
        </Drawer>
      ) : null}
      {openMeeting === true ? (
        <Drawer
          anchor="right"
          open={openMeeting}
          onClose={() => setOpenMeeting(false)}
        >
          <MeetingPopup
            anchor="right"
            dummyReport={dummyReport}
            openMeeting={openMeeting}
            setOpenMeeting={setOpenMeeting}
          />
        </Drawer>
      ) : null}
      {openEmail === true ? (
        <Drawer
          anchor="right"
          open={openEmail}
          onClose={() => setOpenEmail(false)}
        >
          <EmailPopup
            anchor="right"
            dummyReport={dummyReport}
            openEmail={openEmail}
            setOpenEmail={setOpenEmail}
          />
        </Drawer>
      ) : null}

      <Toaster />
    </Card>
  );
};

export default ReportCard;

// <IconButton
//   id="basic-button"
//   aria-controls={openMenu ? "basic-menu" : undefined}
//   aria-haspopup="true"
//   aria-expanded={openMenu ? "true" : undefined}
//   onClick={handleOpenMenu}
//   style={{ marginLeft: "0px", marginRight: "6px" }}
// >
//   <MoreVertIcon style={{ color: "#919EAB" }} />
// </IconButton>

// <Menu
//   anchorEl={anchorEl}
//   open={openMenu}
//   onClose={handleCloseMenu}
//   MenuListProps={{
//     "aria-labelledby": "basic-button",
//   }}
//   style={{ width: "200px" }}
// >
//   <MenuItem
//     onClick={() => {
//       handleCloseMenu();
//       setOpenEmail(true);
//     }}
//   >
//     <ListItemIcon>
//       <img src={emailIcon} alt="" />
//     </ListItemIcon>
//     Mail
//   </MenuItem>
//   <MenuItem
//     onClick={() => {
//       handleCloseMenu();
//       setOpenMeeting(true);
//     }}
//   >
//     <ListItemIcon>
//       <img src={mettingIcon} alt="" />
//     </ListItemIcon>
//     Meeting
//   </MenuItem>
// </Menu>
