import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReportCard from "./components/ReportCard";
import "./report.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import {
  fetchfilteredReports,
  fetchTags,
  fetchViolationtypes,
  fetchLocations,
  fetchStatus,
} from "../../redux/action/report";
import download from "../../images/received.png";
import Notification from "./components/Notification/Notification";
import calendar from "../../images/calendar.png";
import noRecords from "../../images/no-records-found.png";
import Download from "./components/Download/Download";
import Calender from "./components/Calender/Calender";
import { MdRefresh } from "react-icons/md";
import { fetchClientLocations } from "../../redux/action/client";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { getClientDetailsFromLocalStorage } from "../../components/LocalStorage";
import DropdownArrowIcon from "./components/images/DropdownArrow";
const ReportPage = ({
  fetchfilteredReports,
  allReports,
  fetchClientLocations,
  clientLocation,
}) => {
  const dispatch = useDispatch();
  const isBigScreen = useMediaQuery("(min-width:768px)");
  const [direction, setDirection] = useState("column");
  const [tag, setTag] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [violation, setViolation] = useState("");
  const [curAssigned, setCurAssigned] = useState(null);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const selectedDate = useSelector((state) => state.child.selectedDate);
  const [alignment, setAlignment] = useState("web");
  const [assign, setAssign] = useState(false);
  const [refresh, setRefresh] = useState(new Date());
  const [falsePositivesSelected, setFalsePositivesSelected] = useState(false);
  const [falsePositiveCount, setFalsePositiveCount] = useState(0);
  const [falsePositivePercentage, setFalsePositivePercentage] = useState(0);
  const [date, setDate] = useState(new Date());
  const [curClientId, setcurClientId] = useState(null);
  const [userAction, setUserAction] = useState(false);
  const [visibleCount, setVisibleCount] = useState(20);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();

    if (storedClientDetails) {
      setcurClientId(storedClientDetails.client);
      setUserAction(storedClientDetails.action);
      setUserId(storedClientDetails._id)

      // Check if selectedDate is null and set it to the current date
      const dateToday = selectedDate
        ? selectedDate
        : new Date().toISOString().split("T")[0];
      console.log("dateToday", dateToday);

      fetchfilteredReports(storedClientDetails.client, dateToday, dateToday,storedClientDetails._id);
      fetchClientLocations(storedClientDetails.client);
    }
    const intervalId = setInterval(() => {
      setRefresh(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [fetchfilteredReports, selectedDate, refresh, fetchClientLocations]);
  useEffect(() => {
    if (isBigScreen) {
      setDirection("row");
    } else {
      setDirection("column");
    }
  }, [isBigScreen]);

  useEffect(() => {
    dispatch(fetchTags());
    dispatch(fetchLocations());
    dispatch(fetchStatus());
    dispatch(fetchViolationtypes());
  }, [dispatch, refresh]);



  const uniqueTags = ["Violation", "Near miss", "False alert"];
  const allReportsLength =
  allReports && allReports?.reports
    ? allReports?.reports?.length
    : 0;
  console.log(allReportsLength)
  const allStatus =
    allReports && allReports.reports
      ? allReports.reports.map((report) => report.status)
      : [];
  const uniqueStatus = [...new Set(allStatus.flat())];

  const uniqueLocation = clientLocation;

  const allViolation =
    allReports && allReports.reports
      ? allReports.reports.map((report) => report.violationType)
      : [];
  const uniqueViolation = [...new Set(allViolation.flat())];
  

  const filteredReport = useMemo(() => {
    return allReports && allReports.reports
      ? allReports.reports.filter(
          (report) =>
            (tag === null ||
              tag === "" ||
              tag === "ALL" ||
              (report.tags && report.tags.includes(tag))) &&
            (status === null ||
              status === "" ||
              status === "ALL" ||
              (report.status && report.status.includes(status))) &&
            (location === null ||
              location === "" ||
              location === "ALL" ||
              (report.location && report.location.includes(location))) &&
            (violation === null ||
              violation === "" ||
              violation === "ALL" ||
              (report.violationType &&
                report.violationType.includes(violation))) &&
            (falsePositivesSelected ? report.deleted : true) && // Only include deleted:true if falsePositivesSelected is true
            (!assign ||
              (report.assigned && report.assigned === "Unassigned")) &&
            (userAction || !report.deleted) // Exclude deleted:true if userAction is false
        )
      : [];
  }, [allReports, tag, status, location, violation, falsePositivesSelected, assign, userAction]);
  
  useEffect(() => {
    if (filteredReport && filteredReport.length > 0) {
      // Calculate false positives count based on filtered reports
      const countDeleted = filteredReport.filter((report) => report.deleted).length;
      setFalsePositiveCount(countDeleted);
  
      // Calculate percentage
      const totalReports = filteredReport.length;
      const percentage = (countDeleted / totalReports) * 100;
      setFalsePositivePercentage(percentage.toFixed(2)); // Round to 2 decimal places
    } else {
      // Reset counts if there are no filtered reports
      setFalsePositiveCount(0);
      setFalsePositivePercentage(0);
    }
  }, [filteredReport]);
  console.log(filteredReport);
  function openDownloadPopup() {
    setDownloadOpen(!downloadOpen);
  }
  
  const openCalenderPopup = () => {
    setCalenderOpen(!calenderOpen);
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleReportRefresh = async () => {
    try {
      setTag(null);
      setStatus(null);
      setLocation(null);
      setViolation(null);
      setAssign(false);
      setFalsePositivesSelected(false);
      await fetchfilteredReports(curClientId, selectedDate, selectedDate,userId);
    } catch (error) {
      console.error("Error refreshing reports:", error);
    }
  };
  const handleFalsePositiveSelectedClick = async () => {
    setFalsePositivesSelected(
      (prevFalsePostiveSelected) => !prevFalsePostiveSelected
    );
  };
  const handleUnassignedRefresh = async () => {
    setAssign((prevAssign) => !prevAssign);
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleTagChange = (e) => {
    setTag(e.target.value);
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };
  const handleViolationChange = (e) => {
    setViolation(e.target.value);
  };
  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 50);
  };
  if (!allReports || !allReports.reports) {
    return <LoadingSpinner />;
  }
  
  
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        overflow: "hidden",

        backgroundColor: "#F8F8FF",
      }}
    >
      {downloadOpen && (
        <Download
          downloadOpen={downloadOpen}
          setDownloadOpen={(newState) => setDownloadOpen(newState)}
          curClientId={curClientId}
          location={location}
          violationType={violation}
          status={status}
          tag={tag}
        />
      )}
      {notificationOpen && (
        <Notification
          notificationOpen={notificationOpen}
          setNotificationOpen={(newState) => setNotificationOpen(newState)}
        />
      )}
      {calenderOpen && (
        <Calender
          date={date}
          setDate={setDate}
          calenderOpen={calenderOpen}
          setCalenderOpen={(newState) => setCalenderOpen(newState)}
        />
      )}
      <Stack
        id="main-div"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        // margin={"13px"}
        style={{ width: "100%", backgroundColor: "white", heigth: "100%" }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={10}
          style={{
            width: "100%",
            height: "calc(100% - 64px)",
            backgroundColor: "#F8F8FF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.5rem",
            margin: 0,
          }}
        >
          <div style={{ width: "100%", margin: "9px" }}>
            <Typography
              style={{
                fontFamily: "PlusJakartaSans",
                fontSize: "24px",
                fontStyle: "noraml",
                fontWeight: "600",
                lineHeight: "36px",
                color: "#161C24",
                margin: "10px",
              }}
            >
              Reports
            </Typography>
          </div>

          <div
            style={{
              // display:"flex",
              // flexDirection: "column",
              margin: "15px",
              backgroundColor: "white",
              overflow: "auto",
              height: "100%",
              overflowX: "hidden",
              // width: "90%",
              width: "100%",
              padding: "1rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{
                marginTop: "20px",
                marginLeft: "20px",
                marginRigjt: "20px",

                // backgroundColor: "black",
              }}
              spacing={1}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{
                  // marginLeft: "10px",
                  // marginRight: "10px",
                  // backgroundColor: "red",
                  width: "100%",
                }}
                spacing={4}
              >
                <Typography
                  style={{
                    fontFamily: "PlusJakartaSans",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "32px",
                    color: "#161C24",
                  }}
                >
                  {/* Display total filtered incidents */}
                  All Incidents ({filteredReport?.length})
                  {/* Conditionally render additional stats if userAction is true */}
                  {userAction && (
                    <>
                      <span style={{paddingLeft:"1rem"}}>
                        {"   "}
                        {"    False Positives: "}
                        {falsePositiveCount}
                        {"/"}
                        {filteredReport?.length}
                        {"   "}
                        {`(${falsePositivePercentage}%)`}
                      </span>
                    </>
                  )}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  style={{
                    marginTop: "10px",
                    marginRight: "11px",
                    alignItems: "center",
                  }}
                  spacing={2}
                >
                  <MdRefresh
                    className="report-refresh-icon"
                    style={{
                      color: "rgb(64, 64, 242)",
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                    onClick={handleReportRefresh}
                  />
                  {allReports.reports.length !== 0 && (
                    <Button
                      variant="outlined"
                      style={{
                        color: "#4040F2",
                        borderColor: "#4040F2",
                        borderWidth: "1px",
                        textTransform: "none",
                        // alignItems:"center",
                        // justifyContent:"space-between"
                      }}
                      onClick={openDownloadPopup}
                    >
                      Download
                      <img
                        src={download}
                        alt="Download"
                        style={{
                          marginRight: "2px",
                          borderColor: "#4040F2",
                          borderWidth: "2px",
                          marginLeft: "6px",
                        }}
                      />
                    </Button>
                  )}

                  <Button
                    variant="outlined"
                    style={{
                      color: "#4040F2",
                      borderColor: "#4040F2",
                      borderWidth: "1px",
                      textTransform: "none",
                    }}
                    onClick={openCalenderPopup}
                  >
                    {selectedDate
                      ? selectedDate.split("-").reverse().join("/")
                      : date?.toLocaleDateString("en-GB")}
                    <img
                      src={calendar}
                      alt="Calendar"
                      style={{
                        marginRight: "2px",
                        borderColor: "#4040F2",
                        borderWidth: "2px",
                        marginLeft: "6px",
                      }}
                    />
                  </Button>
                </Stack>
              </Stack>

              <Stack
                direction={direction}
                style={{
                  // marginLeft: "10px",
                  // marginRight: "10px",
                  // backgroundColor: "red",
                  width: "100%",
                }}
                spacing={2}
              >
                <FormControl
                  sx={{
                    width: 120,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none ! important",
                    },
                  }}
                >
                  <Select
                    className={`status-select ${
                      status !== null && status !== "" && status !== "ALL"
                        ? "report-status-dropdown-selected"
                        : ""
                    }`}
                    displayEmpty
                    value={status}
                    onChange={handleStatusChange}
                    input={<OutlinedInput className="select-text" />}
                    renderValue={(selected) => {
                      if (!selected || selected === "ALL") {
                        return <em>Status</em>;
                      }

                      return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <DropdownArrowIcon
                        classes={`report-dropdown-arrow-icon ${
                          status !== null && status !== "" && status !== "ALL"
                            ? "report-status-dropdown-arrow-selected"
                            : ""
                        }`}
                      />
                    )}
                  >
                    <MenuItem value="ALL">
                      <em>ALL</em>
                    </MenuItem>
                    {uniqueStatus.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  sx={{
                    width: 120,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none ! important",
                    },
                  }}
                >
                  <Select
                    className={`tag-select ${
                      tag !== null && tag !== "" && tag !== "ALL"
                        ? "report-tag-dropdown-selected"
                        : ""
                    }`}
                    displayEmpty
                    value={tag}
                    onChange={handleTagChange}
                    input={<OutlinedInput className="select-text" />}
                    renderValue={(selected) => {
                      if (!selected || selected === "ALL") {
                        return <em>Tags</em>;
                      }

                      return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <DropdownArrowIcon
                        classes={`report-dropdown-arrow-icon ${
                          tag !== null && tag !== "" && tag !== "ALL"
                            ? "report-tag-dropdown-arrow-selected"
                            : ""
                        }`}
                      />
                    )}
                  >
                    <MenuItem value="ALL">
                      <em>ALL</em>
                    </MenuItem>
                    {uniqueTags.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  sx={{
                    width: 120,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none ! important",
                    },
                  }}
                >
                  <Select
                    className={`location-select ${
                      location !== null && location !== "" && location !== "ALL"
                        ? "report-location-dropdown-selected"
                        : ""
                    }`}
                    displayEmpty
                    value={location}
                    onChange={handleLocationChange}
                    input={<OutlinedInput className="select-text" />}
                    renderValue={(selected) => {
                      if (!selected || selected === "ALL") {
                        return <em>Location</em>;
                      }

                      return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <DropdownArrowIcon
                        classes={`report-dropdown-arrow-icon ${
                          location !== null &&
                          location !== "" &&
                          location !== "ALL"
                            ? "report-location-dropdown-arrow-selected"
                            : ""
                        }`}
                      />
                    )}
                  >
                    <MenuItem value="ALL">
                      <em>ALL</em>
                    </MenuItem>
                    {uniqueLocation.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  sx={{
                    width: 120,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none ! important",
                    },
                  }}
                >
                  <Select
                    className={`violation-select ${
                      violation !== null &&
                      violation !== "" &&
                      violation !== "ALL"
                        ? "report-violation-dropdown-selected"
                        : ""
                    }`}
                    displayEmpty
                    value={violation}
                    onChange={handleViolationChange}
                    input={<OutlinedInput className="select-text" />}
                    renderValue={(selected) => {
                      if (!selected || selected === "ALL") {
                        return <em>Violation</em>;
                      }

                      return selected;
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <DropdownArrowIcon
                        classes={`report-dropdown-arrow-icon ${
                          violation !== null &&
                          violation !== "" &&
                          violation !== "ALL"
                            ? "report-violation-dropdown-arrow-selected"
                            : ""
                        }`}
                      />
                    )}
                  >
                    <MenuItem value="ALL">
                      <em>ALL</em>
                    </MenuItem>
                    {uniqueViolation.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  sx={{
                    backgroundColor: assign
                      ? "rgb(244, 246, 248) !important"
                      : "#f4f6f8",
                    color: assign ? "rgb(86, 86, 243)" : "rgb(234, 236, 238)",
                    height: "40px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "0",
                      padding: "1",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,.MuiButtonBase-root":
                      {
                        // border: "1px solid #eee",
                        border: "none",
                      },
                  }}
                >
                  <ToggleButton
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "0",
                        padding: "1",
                      },
                      backgroundColor: assign
                        ? "rgb(244, 246, 248) !important"
                        : "#f4f6f8",
                      color: assign ? "rgb(86, 86, 243)" : "rgb(234, 236, 238)",
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #eee",
                        },
                      "& .MuiButtonBase-root": {
                        border: "0px",
                      },
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      handleUnassignedRefresh();
                    }}
                  >
                    <span
                      style={{
                        textTransform: "none",
                        fontFamily: "PlusJakartaSans",
                        fontSize: "16px",
                        color: assign === true ? "rgb(86, 86, 244)" : "#454F5B",
                        fontWeight: 400,
                        lineHeight: "24px",
                        fontStyle: "normal",
                        border: 0,
                      }}
                    >
                      Unassigned
                    </span>
                  </ToggleButton>
                </ToggleButtonGroup>
                {userAction && (
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    sx={{
                      backgroundColor: falsePositivesSelected
                        ? "rgb(244, 246, 248) !important"
                        : "#f4f6f8",
                      color: falsePositivesSelected
                        ? "rgb(86, 86, 243)"
                        : "rgb(234, 236, 238)",
                      height: "40px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "0",
                        padding: "1",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,.MuiButtonBase-root":
                        {
                          // border: "1px solid #eee",
                          border: "none",
                        },
                    }}
                  >
                    <ToggleButton
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "0",
                          padding: "1",
                        },
                        backgroundColor: falsePositivesSelected
                          ? "rgb(244, 246, 248) !important"
                          : "#f4f6f8",
                        color: falsePositivesSelected
                          ? "rgb(86, 86, 243)"
                          : "rgb(234, 236, 238)",
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            border: "1px solid #eee",
                          },
                        "& .MuiButtonBase-root": {
                          border: "0px",
                        },
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        handleFalsePositiveSelectedClick();
                      }}
                    >
                      <span
                        style={{
                          textTransform: "none",
                          fontFamily: "PlusJakartaSans",
                          fontSize: "16px",
                          color:
                            falsePositivesSelected === true
                              ? "rgb(86, 86, 244)"
                              : "#454F5B",
                          fontWeight: 400,
                          lineHeight: "24px",
                          fontStyle: "normal",
                          border: 0,
                        }}
                      >
                        False Positives
                      </span>
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
            </Stack>

            {allReports.length === 0 ? (
              <LoadingSpinner /> // Display a loading spinner while reports are being fetched
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    height: "min-content",
                    overflow: "auto",
                    overflowY: "scroll",
                    margin: "10px",
                  }}
                >
                  {filteredReport && filteredReport.length > 0 ? (
                    <>
                      {filteredReport
                        .slice(0, visibleCount)
                        .map((item, index) => (
                          <div
                            key={index}
                            style={{
                              flexBasis: "calc(47.33%)",
                              width: isBigScreen ? "40%" : "100%",
                              boxSizing: "border-box",
                              margin: "14px",
                            }}
                          >
                            <ReportCard
                              dummyReport={item}
                              userAction={userAction}
                              selectedDate={
                                selectedDate
                                  ? selectedDate
                                  : new Date().toISOString().split("T")[0]
                              }
                            />
                          </div>
                        ))}
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgb(248, 249, 254)",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        padding: "12rem",
                        borderRadius: "0.5rem",
                        fontFamily: "PlusJakartaSans",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={noRecords}
                        width="150rem"
                        height="auto"
                        style={{ marginBottom: "1rem" }}
                        alt="No Records Found"
                      />
                      <p>No Incidents Recorded!</p>
                    </div>
                  )}
                </div>
                {visibleCount < filteredReport.length && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={loadMore}
                      style={{
                        padding: "10px 20px",
                        fontSize: "16px",
                        height: "40px",
                        borderRadius: "8px",
                        color: "rgb(64, 64, 242)",
                        borderColor: "rgb(64, 64, 242)",
                        backgroundColor: "white",
                        borderWidth: "1px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Stack>
      </Stack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allReports: state.filtered?.data,
  clientLocation: state.clientLocation.locations,
});

const mapDispatchToProps = {
  fetchfilteredReports,
  fetchClientLocations,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
