import React, { useEffect, useState } from "react";
import "./Profile.css";
import { HiOutlineUser } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, editUser, fetchAllUsers } from "../../redux/action/user";
import { fetchClients } from "../../redux/action/client";
import { getClientDetailsFromLocalStorage } from "../../components/LocalStorage";
import Popup from "reactjs-popup";
import { MdClose } from "react-icons/md";
import EditIcon from "../../images/EditIcon";
import DeleteIcon from "../../images/DeleteIcon";
import toast from "react-hot-toast";
function ProfilePage() {
  const clientDetails = useSelector((state) => state.user.userDetails);
  console.log(clientDetails);
  const allUsers = useSelector((state) => state.user.allUsers);
  const allClients = useSelector((state) => state.client.allClients);
  const [currentClientID, setCurrentClientID] = useState(null);
  const [currentClient, setCurrentClient] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [actionAllowed, setActionAllowed] = useState(false);
  const [updatedName, setUpdatedName] = useState(null);
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState(null);
  const [updatedEmail, setUpdatedEmail] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);

  const dispatch = useDispatch();

  console.log(allUsers);

  console.log(currentClientID);
  console.log(allClients);
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();

    if (storedClientDetails) {
      dispatch(fetchAllUsers(storedClientDetails.client));
      setCurrentClientID(storedClientDetails.client);
      dispatch({ type: "SET_CLIENT_DETAILS", payload: storedClientDetails });

      if (storedClientDetails.action) {
        setActionAllowed(true);
      } else {
        setActionAllowed(false);
      }
    }
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);
  useEffect(() => {
    if (allUsers && allUsers.length > 0) {
      setUsers(allUsers);
    }
  }, [allUsers]);
  useEffect(() => {
    if (users && users.length >= 0) {
      setFilteredUsers(users.filter((user) => user.client === currentClientID));
    }
  }, [users, currentClientID]);

  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
      setCurrentClientID(storedClientDetails.client);
      dispatch({ type: "SET_CLIENT_DETAILS", payload: storedClientDetails });
    }
  }, [dispatch]);
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails && storedClientDetails.action) {
      setActionAllowed(true);
    } else {
      setActionAllowed(false);
    }
  }, []);
  console.log(allUsers);
  console.log(users);
  console.log(filteredUsers);

  useEffect(() => {
    if (allClients) {
      const client = allClients.filter(
        (client) => client._id === currentClientID
      );
      setCurrentClient(client);
    }
  }, [currentClientID, allClients]);

  console.log(currentClient);
  const handleEditSave = (closePopup) => {
    setTimeout(() => {
      if (currentUserId) {
        // Validation for updatedName
        if (!updatedName || updatedName.trim() === "") {
          // Show toast for invalid name
          toast.error("Please enter a valid name.");
          return; // Exit the function without dispatching the action
        }

        // Validation for updatedPhoneNumber
        const phonePattern = /^\d{10}$/; // Change this regex pattern as per your requirement
        if (
          !updatedPhoneNumber ||
          !phonePattern.test(updatedPhoneNumber) ||
          !updatedPhoneNumber ||
          updatedPhoneNumber.trim() === ""
        ) {
          // Show toast for invalid phone number
          toast.error("Please enter a valid phone number.");
          return; // Exit the function without dispatching the action
        }

        const emailPattern = /^\S+@\S+\.\S+$/; // Change this regex pattern as per your requirement
        if (
          !updatedEmail ||
          !emailPattern.test(updatedEmail) ||
          !updatedEmail ||
          updatedEmail.trim() === ""
        ) {
          toast.error("Please enter a valid email address.");
          return;
        }

        dispatch(
          editUser(currentUserId, updatedName, updatedPhoneNumber, updatedEmail)
        );
        setTimeout(() => {
          dispatch(fetchAllUsers(currentClientID));
          setUpdatedName("");
          setUpdatedEmail("");
          setUpdatedPhoneNumber("");
          setCurrentUserId(null);
          setTimeout(() => {
            closePopup();
          }, 100);
        }, 500);
      }
    }, 500);
  };
  const handleEditButtonClick = (userID) => {
    setCurrentUserId(userID);
  };

  const handleDeleteSave = (closePopup) => {
    if (currentUserId) {
      dispatch(deleteUser(currentUserId));
      setTimeout(() => {
        dispatch(fetchAllUsers(currentClientID));
        setCurrentUserId(null);
        setTimeout(() => {
          closePopup();
        }, 100);
      }, 500);
    }
  };
  return (
    <div className="profile-container">
      <div className="profile-title">
        <span className="profile-title-text">Profile</span>
      </div>
      {currentClient &&
        currentClient.map((client, index) => (
          <div className="client-container" key={index}>
            <div className="client-container-in">
              <div className="client-logo-container">
                <img
                  src={client.logo}
                  className="client-logo"
                  alt="Client Logo"
                />
              </div>
              <div className="client-details-container">
                <div className="client-logo-in-container">
                  <HiOutlineUser className="profile-user-icon" />
                </div>
                <div className="client-name-container">
                  <label className="profile-client-label">Client Name</label>
                  <span className="profile-client-span">
                    {client.clientName}
                  </span>
                </div>
              </div>
              <div className="client-duration-container">
                <div className="client-logo-in-container">
                  <SlCalender className="profile-calender-icon" />
                </div>
                <div className="client-name-container">
                  <label className="profile-client-label">
                    Project Duration
                  </label>
                  <span className="profile-client-span">All Time</span>
                </div>
              </div>
            </div>
            <div className="project-duration-container"></div>
          </div>
        ))}
      <div className="user-table-container">
        <span className="user-table-title">Stakeholder</span>
        <div className="user-table">
          <div className="user-table-header">
            <span>NAME</span>
            <span>EMAIL</span>
            <span className="action-span">ACTION</span>
          </div>
          {filteredUsers &&
            filteredUsers.map((user, index) => (
              <div className="user-row" key={index}>
                <div> {user.name}</div>
                <div>{user.email}</div>
                <div className="action">
                  <Popup
                    trigger={
                      <button
                        className="profile-table-in-btn"
                        disabled={!actionAllowed}
                        onClick={() => handleEditButtonClick(user._id)}
                      >
                        <EditIcon className="profile-table-in-icon" />
                      </button>
                    }
                    position="center"
                    modal
                    nested
                    closeOnDocumentClick={false}
                    onOpen={() => {
                      setCurrentUserId(user._id);
                      setUpdatedName(user.name);
                      setUpdatedPhoneNumber(user.phoneNumber);
                      setUpdatedEmail(user.email);
                    }}
                    className="center-popup profile-popup" // Add this class to center the Popup
                  >
                    {(close) => (
                      <div className="edit-popup-box">
                        <div className="edit-popup-title">
                          <span>Edit</span>
                          <MdClose className="close" onClick={close} />
                        </div>
                        <div className="edit-popup-content">
                          <div className="edit-popup-item-container">
                            <label className="edit-popup-content-label">
                              Name
                            </label>
                            <input
                              className="edit-popup-content-input"
                              type="text"
                              value={updatedName}
                              placeholder={"Enter your name"}
                              onChange={(e) => setUpdatedName(e.target.value)}
                            ></input>
                          </div>
                          <div className="edit-popup-item-container">
                            <label className="edit-popup-content-label">
                              Phone Number
                            </label>
                            <input
                              className="edit-popup-content-input"
                              value={updatedPhoneNumber}
                              placeholder={"Enter your phonenumber"}
                              onChange={(e) =>
                                setUpdatedPhoneNumber(e.target.value)
                              }
                              type="text"
                            ></input>
                          </div>
                          <div className="edit-popup-item-container">
                            <label className="edit-popup-content-label">
                              Email
                            </label>
                            <input
                              className="edit-popup-content-input"
                              type="email"
                              value={updatedEmail}
                              onChange={(e) => setUpdatedEmail(e.target.value)}
                              placeholder={"Enter your Email"}
                            ></input>
                          </div>
                        </div>
                        <div className="edit-popup-footer">
                          <button
                            className="edit-popup-btn"
                            onClick={() => handleEditSave(close)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup>
                  <Popup
                    trigger={
                      <button
                        className="profile-table-in-btn"
                        disabled={!actionAllowed}
                      >
                        <DeleteIcon className="profile-table-in-icon" />
                      </button>
                    }
                    position="center"
                    modal
                    nested
                    closeOnDocumentClick={false}
                    onOpen={() => setCurrentUserId(user._id)}
                    className="center-popup profile-popup" // Add this class to center the Popup
                  >
                    {(close) => (
                      <div className="edit-popup-box">
                        <div className="edit-popup-title">
                          <span>Delete</span>
                          <MdClose className="close" onClick={close} />
                        </div>
                        <div className="edit-popup-content">
                          <span className="delete-popup-span">
                            Are you sure want to delete the stakeholder<br></br>
                            <span className="delete-popup-span-name">
                              {user.name}
                            </span>{" "}
                          </span>
                        </div>
                        <div className="edit-popup-footer">
                          <button
                            className="edit-popup-btn"
                            onClick={() => handleDeleteSave(close)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
