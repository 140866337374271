import React, { useEffect, useState } from "react";
import "./overview.css";
import time from "../../images/time.svg";
import violation from "../../images/violation.svg";
import danger from "../../images/danger.svg";
import location from "../../images/location.svg";
import reporttime from "../../images/report-time.svg";
import reportdanger from "../../images/report-danger.svg";
import reportcalender from "../../images/report-calender.svg";
import reportlocation from "../../images/report-location.svg";
import { connect } from "react-redux";
import {
  fetchDailyReports,
  fetchRecentReports,
  fetchfilteredReports,
} from "../../redux/action/report";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ReactApexChart from "react-apexcharts";
import { Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import { getClientDetailsFromLocalStorage } from "../../components/LocalStorage";

import { DateTime } from "luxon";

const Overview = ({
  fetchDailyReports,
  fetchRecentReports,
  dailyReportsData,
  recentReport,
  fetchfilteredReports,
  filteredReportsData,
}) => {
  const date = DateTime.now().toISODate();
  const [refresh, setRefresh] = useState(new Date());
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
      fetchDailyReports(storedClientDetails.client);
      fetchRecentReports(storedClientDetails.client);
      fetchfilteredReports(storedClientDetails.client, date, date,storedClientDetails._id);
    }

    const intervalId = setInterval(() => {
      setRefresh(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [
    date,
    fetchDailyReports,
    fetchRecentReports,
    fetchfilteredReports,
    refresh,
  ]);

  if (
    !dailyReportsData ||
    !dailyReportsData.reports ||
    !recentReport ||
    !filteredReportsData ||
    !recentReport.reports
  ) {
    return <LoadingSpinner />;
  }

  let createdAt;

  if (recentReport && recentReport.reports && recentReport.reports.length > 0) {
    const mostRecentReport = recentReport.reports[0];
    createdAt = new Date(mostRecentReport.createdAt);
    createdAt.setHours(createdAt.getHours() - 5);
    createdAt.setMinutes(createdAt.getMinutes() - 30);
  } else {
    createdAt = new Date();
    createdAt.setHours(createdAt.getHours());
    createdAt.setMinutes(createdAt.getMinutes());
  }

  // Now, `createdAt` contains the appropriate date/time value based on the scenario

  // Calculate the time difference in milliseconds using IST for the current date
  const currentDate = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const indianTimeNow = new Date(currentDate);

  // Calculate time difference in milliseconds using IST
  const timeDifference = indianTimeNow.getTime() - createdAt.getTime();

  // Function to convert milliseconds to human-readable time format
  // function formatTime(milliseconds) {
  //   const seconds = Math.floor(milliseconds / 1000);
  //   const minutes = Math.floor(seconds / 60);
  //   const hours = Math.floor(minutes / 60);
  //   const days = Math.floor(hours / 24);

  //   if (days > 0) {
  //     return days + (days === 1 ? " day" : " days");
  //   } else if (hours > 0) {
  //     return hours + (hours === 1 ? " hour" : " hours");
  //   } else if (minutes > 0) {
  //     return minutes + (minutes === 1 ? " minute" : " minutes");
  //   } else {
  //     return seconds + (seconds === 1 ? " second" : " seconds");
  //   }
  // }

  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  const hoursDifference = Math.floor(
    (timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
  );
  const minutesDifference = Math.floor(
    (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
  );
  const secondsDifference = Math.floor((timeDifference % (60 * 1000)) / 1000);

  let formattedTimeDifference = "";

  if (daysDifference > 0) {
    if (hoursDifference > 0) {
      // Check if there are remaining minutes
      formattedTimeDifference = `${daysDifference} ${
        daysDifference === 1 ? "day" : "days"
      } ${hoursDifference} ${hoursDifference === 1 ? "hr" : "hrs"}`;
    } else {
      formattedTimeDifference = `${daysDifference} ${
        daysDifference === 1 ? "day" : "days"
      }`;
    }
  } else if (hoursDifference > 0) {
    // Check if there are remaining minutes
    if (minutesDifference > 0) {
      formattedTimeDifference = `${hoursDifference} ${
        hoursDifference === 1 ? "hr" : "hrs"
      } ${minutesDifference} ${minutesDifference === 1 ? "min" : "mins"}`;
    } else {
      formattedTimeDifference = `${hoursDifference} ${
        hoursDifference === 1 ? "hr" : "hrs"
      }`;
    }
  } else if (minutesDifference > 0) {
    formattedTimeDifference = `${minutesDifference} ${
      minutesDifference === 1 ? "min" : "mins"
    }`;
  } else if (secondsDifference >= 0) {
    // Added condition for non-negative seconds
    formattedTimeDifference = `${secondsDifference} ${
      secondsDifference === 1 ? "sec" : "secs"
    }`;
  } else {
    formattedTimeDifference = "0 secs"; // If secondsDifference is negative, display as 0 secs
  }

  // Display the formatted time difference
  console.log("Time Difference:", formattedTimeDifference);
  const filterdReports = dailyReportsData.reports.filter(
    (report) =>
      (!report.deleted) // Exclude deleted:true if userAction is false
  )
  const sortedFilterdReports = filterdReports?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const violationTypeCounts = {};
  if (sortedFilterdReports) {
    sortedFilterdReports.forEach((report) => {
      const violationType = report.violationType;
      violationTypeCounts[violationType] =
        (violationTypeCounts[violationType] || 0) + 1;
    });
  }
  // Find the violationType with the maximum count
  let maxViolationType;
  let maxCount = 0;

  for (const [violationType, count] of Object.entries(violationTypeCounts)) {
    if (count > maxCount) {
      maxCount = count;
      maxViolationType = violationType;
    }
  }

  const locationCounts = {};
  if (sortedFilterdReports) {
    sortedFilterdReports.forEach((report) => {
      const location = report.location;
      locationCounts[location] = (locationCounts[location] || 0) + 1;
    });
  }

  // Find the location with the maximum count
  let maxLocation;
  let maxAreaCount = 0;

  for (const [location, count] of Object.entries(locationCounts)) {
    if (count > maxAreaCount) {
      maxAreaCount = count;
      maxLocation = location;
    }
  }
  const filteredAllReports = filteredReportsData?.reports?.filter(
    (report) =>
      (!report.deleted) // Exclude deleted:true if userAction is false
  )
  const cardDetails = [
    {
      text: "Since Last Incident",
      defect: formattedTimeDifference || "N/A",
      image: time,
    },
    {
      text: "Total Violation",
      defect: filteredAllReports
        ? filteredAllReports.length
        : 0,
      image: violation,
    },
    {
      text: "Frequent Violation",
      defect: maxViolationType || "N/A",
      image: danger,
    },
    {
      text: "Most Violated Area",
      defect: maxLocation || "N/A",
      image: location,
    },
  ];

  const targetTimeArray = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "23:59",
  ];

  const timeCounts = {};

  if (
    Array.isArray(filteredAllReports) &&
    filteredAllReports.length > 0
  ) {
    filteredAllReports.forEach((entry) => {
      const formattedTime = formatTime(entry.createdAt);
      timeCounts[formattedTime] = (timeCounts[formattedTime] || 0) + 1;
    });
  } else {
    console.error("Invalid dailyReportsData format");
  }

  // eslint-disable-next-line no-redeclare
  function formatTime(time) {
    if (time) {
      const hour = new Date(time).getUTCHours();
      const formattedHour = hour.toString().padStart(2, "0");
      return `${formattedHour}:00`;
    }
    return "N/A";
  }

  // Create the count array based on the specified time array
  const countArray = targetTimeArray.map(
    (targetTime) => timeCounts[targetTime] || 0
  );

  // Calculate total count
  const totalCount = countArray.reduce((sum, count) => sum + count, 0);

  // Create the final object
  const graph = { time: targetTimeArray, count: countArray, total: totalCount };

  const maximumCount = Math.max(...graph.count);
  const chartData = {
    series: [
      {
        name: "series1",
        data: [...graph.count],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.7,
            opacityFrom: 0.9,
            opacityTo: 0.4,
            stops: [0, 100],
          },
        },
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      xaxis: {
        categories: targetTimeArray,
        tickAmount: 24,
        title: {
          text: "hours",
          style: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "PlusJakartaSans", // Adjust the font size as needed
          },
        },
        labels: {
          style: {
            fontSize: "9px",
            fontWeight: "600",
            fontFamily: "PlusJakartaSans",
          },
        },
      },
      yaxis: {
        min: 0,
        max: maximumCount <= 5 ? 5 : Math.ceil(maximumCount / 5) * 5,
        tickAmount: 5,
        title: {
          text: "Incidents",
          style: {
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "PlusJakartaSans",
          },
        },
        labels: {
          style: {
            fontSize: "9px",
            fontWeight: "600",
            fontFamily: "PlusJakartaSans",
          },
        },
        grid: {
          strokeDashArray: 10,
          stroke: "#FFF",
        },
      },
      tooltip: {
        x: {
          format: "HH:mm",
        },
      },
      colors: ["#143F7A"],
      fill: {
        colors: ["#143F7A"],
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0.2,
          stops: [0, 100],
        },
      },
    },
  };
  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString)
      .toLocaleDateString("en-GB", options)
      .replace(/\//g, "-");
  }
  function formatTimed(dateString) {
    // Parse the input date string and remove milliseconds and offset
    const date = new Date(
      dateString.replace(/(\d+\-\d+\-\d+T\d+:\d+:\d+).*/, "$1")
    );

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata", // Set to Indian Standard Time
    };

    // Use toLocaleString with timeZone option
    const formattedTime = date.toLocaleString("en-US", options);

    return formattedTime;
  }


 
  return (
    <div className="overview-main">
      <div className="heading-container">
        <span className="heading"> Overview</span>
      </div>

      <div className="card-container">
        {cardDetails.map((card, index) => (
          <Card className="overview-card">
            <CardContent className="card-content">
              <div className="card-align">
                <div className="card-img">
                  <img src={card.image} alt="" className="card-image"></img>
                </div>
                <div className="card-text">
                  <span className="overview-card-text">{card.text}</span>
                  <span className="overview-card-defect">{card.defect}</span>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      <Card className="graph-card">
        <p className="overview-header">Infographic</p>
        <div className="react-line-chart">
          {" "}
          {/* Add a wrapper div for the chart */}
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height="100%"
          />
        </div>
      </Card>
      <Card className="report-main-card">
        <div className="card-column">
          <p className="overview-header">Recent Incidents</p>
          <Link to="/report" className="show-more-link">
            Show More
            <IoMdArrowDropright />
          </Link>
        </div>
        <div className="report-card-container">
          {sortedFilterdReports?.slice(0, 3).map((report, index) => (
            <Card key={index} className="report-card">
              <div className="report-image-div">
                <img src={report.imagepath} alt="" className="report-image" />
              </div>
              <div className="report-text-content">
                <div className="report-text">
                  <img src={reportcalender} alt="" height={17} width={17} />
                  <span className="recent-card-text">
                    {formatDate(report.createdAt)}
                  </span>
                </div>
                <div className="report-text">
                  <img src={reporttime} alt="" height={17} width={17} />
                  <span className="recent-card-text">
                    {formatTimed(report.createdAt)}
                  </span>
                </div>
                <div className="report-text">
                  <img src={reportlocation} alt="" height={17} width={17} />
                  <span className="recent-card-text">{report.location}</span>
                </div>
                <div className="report-text">
                  <img src={reportdanger} alt="" height={17} width={17} />
                  <span className="recent-card-text">
                    {report.violationType}
                  </span>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dailyReportsData: state.dailyReport.data,
  recentReport: state.recentReport.data,
  filteredReportsData: state.filtered.data,
});

const mapDispatchToProps = {
  fetchDailyReports,
  fetchRecentReports,
  fetchfilteredReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
